import '../styles/globals.css'
import '../styles/styles.scss'
import type { AppProps } from 'next/app'
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';


function MyApp({ Component, pageProps }: AppProps) {

  useEffect(() => {
    if(process.env.NODE_ENV === "production"){
      TagManager.initialize({ gtmId: 'GTM-WW8V8HS' });
    }
  }, []);
  return <Component {...pageProps} />
}

export default MyApp
